<template>
  <article class="post">
    <div class="post_banner">
      <div class="gallery_image_1">
        <nuxt-link :to="'/cikk/'+ article.id +'/' + article.slug">
          <img
            v-lazy-load :data-src="article.thumbnail && article.thumbnail.conversions && article.thumbnail.conversions.responsive
              && article.thumbnail.conversions.responsive.src
              ? article.thumbnail.conversions.responsive.src :
                article.thumbnail.url"
            v-lazy-load :data-srcset="article.thumnail && article.thumbnail.conversions && article.thumbnail.conversions.responsive
              && article.thumbnail.conversions.responsive.srcset
              ? article.thumbnail.conversions.responsive.srcset :
                article.thumbnail.url"
            :alt="article.title"
            sizes="750px"
          >
        </nuxt-link>
      </div>
    </div>
    <div class="post_body">
      <div class="post_meta">
        <span class="post_meta_item post_author">
          <nuxt-link to="/rolam">
            <span class="post_author_img">
              <img v-lazy-load data-src="/img/authors/papai_anita_thumb.jpg" alt="Pápai Anita">
            </span>
            <span class="post_author_info">Nita</span>
          </nuxt-link>
        </span>

        <!-- <a href="#"> -->
        <span class="post_meta_item meta_item_date">{{ date(article.published_at ? article.published_at : article.created_at) }}</span>
        <!-- </a> -->

        <span v-for="(category, index2) in article.categories_list" :key="index2" class="post_meta_item post_cat">
          {{ index2 === 0 ? "&nbsp;" : "" }}
          <nuxt-link :to="'/kereses?search=' + category.name">
            <span>{{ category.name }}</span>
          </nuxt-link>
          {{ index2 !== article.categories_list.length-1 ? "&nbsp;" : "" }}
        </span>
      </div>

      <div class="post_header">
        <h3>
          <nuxt-link :to="'/cikk/'+ article.id +'/' + article.slug">
            {{ article.title }}
          </nuxt-link>
        </h3>
      </div>

      <div class="post_info_wrapper">
        <p>
          {{ article.description }}
        </p>
      </div>

      <div class="post_bottom_meta">
        <!-- <div class="half_width">
          <span class="meta meta_comment">
            <a href="#"><span class="jam jam-message" /> 3 Comments</a>
          </span>

          <div class="post_more">
            <a href="#">
              <span class="icon1">Load More</span>
              <span class="icon2 jam jam-arrow-right" />
            </a>
          </div>
        </div> -->

        <div class="w-100">
          <div :class="{'show-socials': showSocials}" @click="showSocials = true" class="socials-wrap">
            <div class="socials-icon">
              <i class="fa fa-share-alt" aria-hidden="true" />
            </div>
            <div class="socials-text">
              Cikk megosztása
            </div>
            <ul class="socials">
              <li><a @click="facebookShare()" href="#"><span class="jam jam-facebook" /></a></li>
              <li><a @click="twitterShare()" href="#"><span class="jam jam-twitter" /></a></li>
              <li><a :href="mailShare()"><span class="jam jam-envelope" /></a></li>
              <!-- <li><a href="#" target="_blank"><span class="jam jam-google-plus" /></a></li>
              <li> <a href="#" target="_blank"><span class="jam jam-pinterest" /></a> </li> -->
            </ul>
          </div>
        </div>

        <!-- <div class="half_width">
          <div class="socials-wrap">
            <div class="socials-icon">
              <i class="fa fa-share-alt" aria-hidden="true" />
            </div>
            <div class="socials-text">
              Cikk megosztása
            </div>
            <ul class="socials">
              <li><a href="#" target="_blank"><span class="jam jam-facebook" /></a></li>
              <li><a href="#" target="_blank"><span class="jam jam-twitter" /></a></li>
              <li><a href="#" target="_blank"><span class="jam jam-google-plus" /></a></li>
              <li>
                <a href="#" target="_blank"><span class="jam jam-pinterest" /></a>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
  </article>
</template>
<script>
export default {
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showSocials: false
    }
  },
  methods: {
    date (value) {
      return this.$moment(value, 'YYYY-MM-DD').format('Y. MMMM D.')
    },
    url () {
      return process.env.frontendUrl + '/cikk/' + this.article.id + '/' + this.article.slug
    },
    mailShare () {
      return 'mailto:?subject=' +
        'A tejhabos mindenit: ' + this.article.title +
        '&body=' +
        this.article.description + '...' +
      '%0D%0D A teljes tartalom "A tejhabos mindenit!" weboldalán érhető el:%0D ' +
        encodeURIComponent(this.url())
    },
    facebookShare () {
      window.open(
        'https://www.facebook.com/sharer/sharer.php?u=' + this.url(),
        'facebook-share-dialog',
        'width=626,height=436',
        '_blank'
      )
    },
    twitterShare () {
      window.open(
        'https://twitter.com/intent/tweet?url=' +
          this.url(),
        // (this.text ? +'&text=' + this.text : +'') +
        // "&via=folkradiohu",
        '_blank'
      )
    }
  }
}
</script>
<style lang="scss">
  .post_author_img {
    width: 100%;
  }

  .meta_item_date {
    margin-left: 15px;
    display: inline-block;
  }

  .show-socials {
    .socials {
        opacity: 1 !important;
        display: inline-block !important;

    }
    .socials-text {
        transform: translate(36px, -34px);
    }

    .socials-icon {
        transform: translate(-8px, -34px) !important;
        opacity: 0;
    }
  }
</style>
