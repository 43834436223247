<template>
  <div>
    <loader v-if="$fetchState.pending" />
    <div id="content_full">
      <div class="container">
        <div class="row sticky-container">
          <div class="col-lg-8 content">
            <div id="content" class="latest-posts">
              <post-preview v-for="(article, index) in articles.data" :article="article" :key="index" />
              <div class="w-100 text-center">
                <h4 v-if="articles.current_page !== articles.last_page">
                  <button @click="showMore()" class="link">
                    Kérek még!
                  </button>
                </h4>
                <h4 v-else>
                  Nincs több cikk egyelőre
                </h4>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <sidebar />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PostPreview from '~/components/front/post/post-preview.vue'
import loader from '~/components/front/loader.vue'
import sidebar from '~/components/front/sidebar.vue'
// const sidebar = () =>
//   import('~/components/front/sidebar.vue')

export default {
  layout: 'main',
  components: {
    sidebar,
    loader,
    PostPreview
  },
  head () {
    return {
      titleTemplate: 'A tejhabos mindenit',
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: 'A tejhabos mindenit'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: 'A tejhabos mindenit'
        },
        {
          hid: 'description',
          name: 'description',
          content: 'Merülj el a kávézás világában és merj velem együtt nyitni az új felé, merj szabadon gondolkodni magadról és a társadalomról. A tejhabos mindenit! rólam és Rólad, nekem és Neked szól, és mindazoknak, akik minket a tejhabos feketékkel boldoggá tesznek.'
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: 'Merülj el a kávézás világában és merj velem együtt nyitni az új felé, merj szabadon gondolkodni magadról és a társadalomról. A tejhabos mindenit! rólam és Rólad, nekem és Neked szól, és mindazoknak, akik minket a tejhabos feketékkel boldoggá tesznek.'
        },
        {
          hid: 'og:url',
          name: 'og:url',
          property: 'og:url',
          content: this.url()
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: process.env.frontendUrl + '/img/tejhabos-logo-dark.png'
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: 'A tejhabos mindenit'
        }
      ]
    }
  },
  data () {
    return {
      articles: {
        data: []
      }
    }
  },
  async fetch () {
    try {
      const response = await this.$axios.get('/articles?limit=10&sort=-published_at')

      this.articles = response.data
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    async showMore () {
      try {
        const nextPage = this.articles.current_page + 1
        const response = await this.$axios.get('/articles?limit=10&sort=-published_at&page=' + nextPage)
        this.articles.current_page = nextPage

        response.data.data.forEach((item) => {
          this.articles.data.push(item)
        })
      } catch (e) {
        console.log(e)
      }
    },
    url () {
      return process.env.frontendUrl
    }
  }
}
</script>
