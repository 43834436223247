<template>
  <article class="recent_post">
    <div class="post-media">
      <nuxt-link :to="'/cikk/'+ article.id +'/' + article.slug">
        <img
          v-lazy-load :data-src="article.thumbnail && article.thumbnail.conversions && article.thumbnail.conversions.responsive
            && article.thumbnail.conversions.responsive.src
            ? article.thumbnail.conversions.responsive.src :
              article.thumbnail.url"
          v-lazy-load :data-srcset="article.thumnail && article.thumbnail.conversions && article.thumbnail.conversions.responsive
            && article.thumbnail.conversions.responsive.srcset
            ? article.thumbnail.conversions.responsive.srcset :
              article.thumbnail.url"
          :alt="article.title"
          sizes="750px"
        >
      </nuxt-link>
    </div>
    <div class="post_info">
      <!-- <p> -->
      <span v-for="(category, index2) in article.categories_list" :key="index2">
        <nuxt-link :to="'/kereses?search=' + category.name">{{ category.name }}</nuxt-link>{{ index2 !== article.categories_list.length-1 ? "&nbsp;-&nbsp;" : "" }}
      </span>
      <!-- </p> -->
      <h5>
        <nuxt-link :to="'/cikk/'+ article.id +'/' + article.slug">
          {{ article.title }}
        </nuxt-link>
      </h5>
    </div>
  </article>
</template>
<script>
export default {
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  methods: {
    date (value) {
      return this.$moment(value, 'YYYY-MM-DD').format('Y. MMMM D.')
    }
  }
}
</script>
