<template>
  <div id="sidebar" class="sidebar light-sidebar">
    <div class="sidebar__inner">
      <div class="sidbar_w">
        <div class="widget_area area_about d-none d-lg-block">
          <div class="about">
            <h3 class="widget_title">
              <span>Rólam</span>
            </h3>
            <nuxt-link to="/rolam">
              <img v-lazy-load data-src="/img/authors/papai_anita.jpg" alt="Pápai Anita">
            </nuxt-link>
            <p>
              Merülj el a kávézás világában és merj velem együtt nyitni az új felé, merj szabadon gondolkodni magadról és a társadalomról. A tejhabos mindenit! rólam és Rólad, nekem és Neked szól, és mindazoknak, akik minket a tejhabos feketékkel boldoggá tesznek.
            </p>
            <p class="sign">
              Pápai Anita
            </p>
          </div>
        </div>

        <div class="widget_area recent_posts">
          <h3 class="widget_title">
            <span>Legutóbbi cikkek</span>
          </h3>

          <post-last v-for="(article, index) in lastArticles" :key="index" :article="article" />
        </div>

        <div class="widget_area social_icons_area">
          <h3 class="widget_title">
            <span>Kövess engem</span>
          </h3>

          <div class="scoial-icon">
            <a href="https://www.facebook.com/atejhabosmindenit" target="_blank"><span><i class="fab fa-facebook-f" /></span></a>
            <!-- <a href="#0"><span><i class="fab fa-twitter" /></span></a>
            <a href="#0"><span><i class="fab fa-linkedin-in" /></span></a> -->
            <a href="https://www.instagram.com/atejhabosmindenit/" target="_blank"><span><i class="fab fa-instagram" /></span></a>
            <!-- <a href="#0"><span><i class="fab fa-behance" /></span></a> -->
          </div>
        </div>

        <div class="widget_area instagram_feed">
          <h3 class="widget_title">
            <span>Instagram</span>
          </h3>

          <ul class="instagram_pics">
            <li>
              <a href="https://www.instagram.com/atejhabosmindenit/" target="_blank">
                <img v-lazy-load data-src="/img/instagram/instagram7.jpg" alt="">
                <div class="instagram-overlay">
                  <!-- <div class="instagram-meta">
                    <div>
                      <span class="instagram-likes"><i class="fas fa-heart" />
                        1</span>
                      <span class="instagram-comments"><i class="far fa-comments" />
                        3</span>
                    </div>
                  </div> -->
                </div>
              </a>
            </li>

            <li>
              <a href="https://www.instagram.com/atejhabosmindenit/" target="_blank">
                <img v-lazy-load data-src="/img/instagram/instagram8.jpg" alt="">
                <div class="instagram-overlay">
                  <!-- <div class="instagram-meta">
                    <div>
                      <span class="instagram-likes"><i class="fas fa-heart" />
                        1</span>
                      <span class="instagram-comments"><i class="far fa-comments" />
                        3</span>
                    </div>
                  </div> -->
                </div>
              </a>
            </li>

            <li>
              <a href="https://www.instagram.com/atejhabosmindenit/" target="_blank">
                <img v-lazy-load data-src="/img/instagram/instagram9.jpg" alt="">
                <div class="instagram-overlay">
                  <!-- <div class="instagram-meta">
                    <div>
                      <span class="instagram-likes"><i class="fas fa-heart" />
                        1</span>
                      <span class="instagram-comments"><i class="far fa-comments" />
                        3</span>
                    </div>
                  </div> -->
                </div>
              </a>
            </li>

            <li>
              <a href="https://www.instagram.com/atejhabosmindenit/" target="_blank">
                <img v-lazy-load data-src="/img/instagram/instagram10.jpg" alt="">
                <div class="instagram-overlay">
                  <!-- <div class="instagram-meta">
                    <div>
                      <span class="instagram-likes"><i class="fas fa-heart" />
                        1</span>
                      <span class="instagram-comments"><i class="far fa-comments" />
                        3</span>
                    </div>
                  </div> -->
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PostLast from '~/components/front/post/post-last.vue'
// const PostLast = () =>
//   import('~/components/front/post/post-last.vue')
export default {
  components: {
    PostLast
  },
  data () {
    return {
      showSidebar: false
    }
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      lastArticles: 'articles'
      // ...
    })
  }
}
</script>
<style lang="scss">
 .sign {
   font-family: 'Parisienne', cursive;
   font-size: x-large;
 }
</style>
